import React,{useState,useEffect} from "react"
import '../components/layout.css'
import  CortexLogo from '../images/Cortex Outline.svg'
import NotFoundLogo from '../images/polaris-icon-404.svg'
import { navigate } from 'gatsby'
import SEO from '../components/seo'

const NotFound = () => {

    const [timerval,setTimerVal]=useState(5);

        function notFoundSubmit(wat){
        clearInterval(wat)
          return navigate("//")
      }

        function timerTick(){
          let x=5             
          let war = setInterval(() => {
           x= x-1
           if (x===1)
           { 
           notFoundSubmit(war)
           }
           setTimerVal(x);
          }, 900);
        }
  
        useEffect(async()=>{
           timerTick()
        },[])

    return (
    <React.Fragment>
        <SEO title="404 : Not Found"/>
        <div className="cortex-404-bg">
            <div id="cortex-404-form">
                <img src={CortexLogo}  id="cortex-404-logo" alt="CortexLogo" />
                <img src={NotFoundLogo} id="cortex-404-logo-nf"  alt="NotFoundLogo"/>
                <p id="cortex-404-p-nf">Page not found</p>
                <p id ="cortex-404-p-nf-1">Redirecting you to the login page in {timerval} {timerval<1 ?"second":"second(s)"}</p>
            </div>
        </div>
    </React.Fragment>)
}

export default NotFound