
import React from 'react'
import Helmet from 'react-helmet'
import SeoImage from '../images/Cortex.jpg'

function SEO({description,lang,meta,title,image}){

    return(
        <Helmet
        htmlAttributes={
            lang
        }
        title={title}
        titleTemplate={''}
        meta={[
            {
              name: `title`,
              content: "Cortex Technologies",
            },  
            {
              property: `og:image`,
              content:SeoImage || image,
            },
            {
              property: `og:title`,
              content: title,
            },
            {
              property: `og:description`,
              content: description,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
            {
              name: `twitter:creator`,
              content:"N/A",
            },
            {
              name: `twitter:title`,
              content:"N/A",
            },
            {
              name: `twitter:description`,
              content:"N/A",
            }
        ]}
        />
    )
}

export default SEO